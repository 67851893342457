.find {
  margin-bottom: 200px;
}

.find-type {
  display: flex;
  justify-content: space-evenly;
}
.find-type > div {
  cursor: pointer;
  font-size: 20px;
}
.find-type > .checked {
  color: #f91137;
  text-decoration: underline;
  font-weight: 700;
}
.find-hr {
  margin-top: 50px;
  width: 700px;
}
/* .find-hr:last-of-type {
  margin-top: 0px;
  margin-bottom: 50px;
} */
.find-inputs {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  margin-top: 40px;
  height: 240px;
}

.find-inputs > .find-input-container {
  display: flex;
  /* width: 700px; */
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.find-inputs > .find-input-container > p {
  width: 90px;
}

.find-inputs > .find-input-container > input {
  width: 50%;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  font-size: 18px;
  /* margin-top: 10px; */
  /* margin-bottom: 40px; */
  /* padding-left: 20px; */
  /* line-height: 56px; */
}

.find-btn-container {
  display: flex;
  justify-content: center;
}
.find-btn {
  width: 150px;
  height: 50px;
  background-color: #f91137;
}

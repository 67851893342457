.carousel-container {
    width: 1440px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 200px;
}
.carousel {
    width: 1940px;
    height: 400px;
    margin: 80px 0 0;
}

.carousel .slick-list {
    overflow: visible;
}
.carousel .slick-slider {
    text-align: center;
    height: 200px;
}
.carousel .slick-slide {
    width: 650px;
    height: 380px;
    padding: 60px;
    box-sizing: border-box;
}

.carousel .slick-slide > div > div {
    background-color: transparent;
}

.carousel .slick-slide .thumbnail-context {
    display: none;
}
.carousel .slick-current .thumbnail-context {
    display: inline-flex;
}

.thumbnail-context {
    position: relative;
    top: -60px;
    display: inline-flex;
    width: calc(100% - 60px);
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    background-color: #ffffff;
    border-radius: 20px;
    height: 86px;
    text-align: left;
}
.thumbnail-context > div > p {
    font-weight: 800;
    font-size: 14px;
    color: #f91137;
    margin-bottom: 8px;
}
.thumbnail-context > div > span:first-of-type {
    display: -webkit-inline-box;
    font-size: 18px;
    max-width: 260px;
    margin-right: 5px;
}
.thumbnail-context > div > span:last-of-type {
    font-size: 14px;
    font-weight: 500;
    color: #c0c0c0;
}

.thumbnail-context > img {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.thumbnail {
    cursor: pointer;
}

.slick-active:not(.slick-current) > div > div > div > img {
    height: 277.86px !important;
}

.carousel .slick-slide > div > div > div > img {
    width: 100%;
    /* height: 100%; */
    filter: grayscale(100%);
    border-radius: 20px;
}

.slick-current > div > div > div > img {
    height: 345px;
}

.carousel .slick-current {
    padding: 0px;
    margin-top: 20px;
}
.carousel .slick-current > div > div > div > img {
    filter: none;
}

.carousel .slick-arrow {
    width: 494px;
    height: 330px;
}

.carousel .slick-next {
    top: 225px;
    right: 105px;
}
.carousel .slick-prev {
    top: 225px;
    left: 105px;
    z-index: 1;
}

.carousel .slick-arrow:before {
    content: "";
}

@media (max-width: 640px) {
    /* 애드파워는 지금 */
    .main-now {
        height: 468px;
    }
    .carousel-container {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) scale(0.47);
    }
    .carousel {
        margin-top: 0;
        height: 484px;
    }
    .thumbnail-context {
        position: relative;
        padding: 36px 30px !important;
    }
    .thumbnail-context > div {
        position: relative;
        width: 100%;
    }
    .thumbnail-context > div > p {
        font-size: 24px;
    }
    .thumbnail-context > div > span:first-of-type {
        width: 100%;
        min-width: 100%;
        font-size: 2rem !important;
    }
    .thumbnail-context > div > span:last-of-type {
        display: block;
        margin: 4px 0 0;
        font-size: 1.4rem !important;
    }
    .thumbnail-context > img {
        width: 56px;
        height: 56px;
    }
}

.forum {
  margin-top: 60px;
}

.forum-dp {
  text-align: center;
  margin-bottom: 60px;
}
.img_dp {
  width: 1124px;
  cursor: pointer;
}

.forum-search {
  margin-bottom: 60px;
}
.forum-search > div {
  margin-left: auto;
  display: flex;
  align-items: center;
  width: 281px;
  height: 36px;
  border-radius: 50px;
  padding-left: 20px;
  background-color: #ffffff;
}

.forum-search input {
  width: inherit;
  height: inherit;
  border: none;
  background-color: transparent;
  font-size: 16px;
}

.forum-search img {
  margin-left: auto;
  width: 18px;
  height: 20px;
  padding: 10px 20px;
}

.forum-main {
  overflow: visible; /* 그림자 안잘리게*/
  /* margin-top: 60px; */
}

.forum-main:not(:has(.pagination)) {
  margin-bottom: 200px;
}

.forum-main .forum-top {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  text-align: center;
}

.forum-main .forum-top span {
  font-size: 20px;
  color: #a5a5a5;
}

.top-num {
  flex: 0.5;
}
.top-title {
  flex: 4;
}
.top-name {
  flex: 1.5;
}
.top-view {
  flex: 0.5;
}
.top-date {
  flex: 1;
}

.forum-main hr {
  margin-top: 15px;
}

.forum-list > .list-container {
  font-size: 18px;
  height: 60px;
  line-height: 60px;
  margin-top: 16px;
  border-radius: 50px;
  border: none;
}
.forum-list > .list-container > a {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  text-align: center;
  text-decoration-line: none;
  color: #1f1f1f;
}

.forum-list > .now_post {
  background-color: #f91137;
}
.forum-list > .now_post > a {
  color: #ffffff;
}
.forum-list > .now_post > a > .forum-important > .img_star {
  display: none;
}
.forum-list > .now_post > a > .forum-important > .img_starwhite {
  display: block;
}
@media (hover: hover) {
  .forum-list > .list-container:hover {
    background-color: #f91137;
  }

  .forum-list > .list-container:hover > a {
    color: #ffffff;
  }

  .forum-list > .list-container:hover > a > .forum-important > .img_star {
    display: none;
  }

  .forum-list > .list-container:hover > a > .forum-important > .img_starwhite {
    display: block;
  }
}
.forum-list > .list-container > a > .forum-important {
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.forum-important > img {
  width: 23.33px;
  height: 22.17px;
  line-height: 30px;
}
.forum-important > .img_starwhite {
  display: none;
}

.forum-list > .list-container > a > .forum-num {
  flex: 0.5;
}
.forum-list > .list-container > a > .forum-title {
  flex: 4;
  text-align: left;
}
.forum-list > .list-container:has(.forum-important) > a > .forum-title {
  font-weight: 500;
}
.forum-list > .list-container > a > .forum-name {
  flex: 1.5;
  display: flex;
  align-items: center;
  width: 205px;
}
.forum-list > .list-container > a > .forum-name > img {
  margin-right: 5px;
  width: 20px;
}
.forum-list > .list-container > a > .forum-view {
  flex: 0.5;
}
.forum-list > .list-container > a > .forum-date {
  flex: 1;
}

.forum-bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  overflow: hidden;
}

.forum-bottom .btn-write {
  float: right;
}

.forum-bottom .btn-totop {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.img_top {
  width: 14px;
  margin-right: 8px;
}

.no_data {
  text-align: center;
  margin: 200px;
  font-size: 20px;
}

/* -------------------- */
.forum-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 32px;
}

.forum-grid:has(.no_data) {
  display: block;
}

.grid-container {
  border-radius: 20px;
  height: 346px;
}

.grid-container img {
  display: block;
  width: 100%;
  height: 251px;
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}

.grid-container > a {
  text-decoration-line: none;
}
.grid-container > a > .grid-star {
  position: absolute;
  font-size: 28px;
  margin-top: 16px;
  margin-left: 16px;
  color: #f91137;
}

.grid-container > a > .grid-contents {
  height: 55px;
  border-radius: 0px 0px 20px 20px;
  padding: 19px 18px 21px 25px;
}

.grid-contents > .forum-title {
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #1f1f1f;
  width: 303px;
}
.forum-card-info {
  display: flex;
  font-size: 0.95rem;
  justify-content: space-between;
  opacity: 0.65;
}

@media (max-width: 640px) {
  .forum-list > .list-container > a {
    flex-direction: column;
    padding: 16px 24px;
    font-size: 1rem;
    gap: 8px;
  }
  .forum-list > .list-container {
    position: relative;
    height: auto;
    line-height: 1.5rem;
    box-sizing: border-box;
    border-radius: 28px;
  }

  .forum-main .forum-top,
  .forum-main hr,
  .top-num,
  .top-view,
  .forum-list > .list-container > a > .forum-important,
  .forum-list > .list-container > a > .forum-view,
  .forum-num {
    display: none;
  }
  .list-container:has(.forum-important)::before {
    position: absolute;
    content: "★";
    padding: 16px 20px;
    color: var(--adpower);
  }
  .list-container:has(.forum-important):hover::before,
  .now_post:has(.forum-important)::before {
    position: absolute;
    content: "★";
    color: #fff;
  }
  .forum-list > .list-container:has(.forum-important) > a > .forum-title {
    padding: 0 0 0 24px;
  }
  .forum-list > .list-container > a > .forum-name > img {
    width: 16px;
  }
  .forum-list > .list-container > a > .forum-name {
    opacity: 0.75;
    font-size: 0.85rem;
  }
  .forum-list > .list-container > a > .forum-date {
    position: absolute;
    padding: 16px 20px;
    right: 0;
    bottom: 0;
    opacity: 0.75;
    font-size: 0.85rem;
  }

  .no_data {
    margin: 120px 0;
    font-size: 1.1rem;
  }
  .img_dp {
    width: 100%;
  }
  .forum-dp {
    margin: 0 0 40px;
  }

  /* grid */
  .forum-grid {
    grid-template-columns: 1fr;
  }
  .grid-container img {
    object-fit: cover;
  }
}

.site-menu,
.site-menu ul {
    padding: 0;
    list-style: none;
    text-decoration: none;
}
.site-menu-items {
    margin: 0 0 22px 0;
}
.site-menu input {
    display: none;
}
.site-menu input:checked + .menu-title + .menu-items {
    display: flex;
}
.menu-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.65rem;
    font-weight: 600;
    letter-spacing: -0.5px;
}
.menu-title::after {
    content: "";
    display: inline-block;
    margin-left: 10px;
    width: 12px;
    height: 6px;
    background-color: #000;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
}
.site-menu input:checked + .menu-title::after {
    transform: rotate(180deg);
}

.menu-items {
    display: none;
    margin: 12px 0 24px;
    flex-wrap: wrap;
    gap: 8px;
}
.menu-item {
    display: inline-block;
    padding: 10px 22px;
    background: #eeeeee;
    border-radius: 30px;
    line-height: 27px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.16px;
    color: var(--colorFg3);
}

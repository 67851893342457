.recruit {
    display: flex;
    justify-content: space-evenly;
    margin: 100px 0;
}

.card-gallery {
    width: 260px;
    height: 430px;
}

.card-gallery > img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.recruit-card-name {
    position: relative;
    bottom: 60px;
    left: 31px;
    color: #ffffff;
    font-size: 28px;
    font-weight: 700;
}

.recruit-container {
    display: flex;
    margin-bottom: 200px;
    flex-direction: column;
}

@media (max-width: 640px) {
    .recruit {
        margin: 60px 0 0;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .card-gallery {
        width: 100%;
        height: 45vw;
        max-height: 430px;
    }
    .recruit-card-name {
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    }
    .card-gallery > img {
        object-fit: cover;
        object-position: top;
        border-radius: 36px;
    }
}

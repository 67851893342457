.draft {
    width: 400px;
    /* height: 240px; */
    position: absolute;
    background-color: #ffffff;
    border-radius: 20px;
    color: #1f1f1f;
    cursor: auto;
    padding: 29px;
    /* left: 640px; */
    margin-left: -370px;
    margin-top: 17px;
    z-index: 2;

    text-align: left;
}

.draft-top {
    display: flex;
    justify-content: space-between;
}

.draft-head {
    font-size: 18px;
    font-weight: 700;
}

.draft-btn {
    cursor: pointer;
}
@media (hover: hover) {
    .draft-btn:hover {
        color: #f91137;
        font-weight: 700;
    }
}
.draft-list {
    margin-top: 27px;
}
.draft-list > hr {
    margin: 9px 0px;
}
.draft-list-head > span {
    font-size: 16px;
    color: #b5b5b5;
}
.draft-list-head > .draft-count {
    color: #f91137;
}
.draft-list-head > .draft-deleteall {
    float: right;
    margin-right: 8px;
    cursor: pointer;
}

.draft-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.draft-content:not(:last-of-type) {
    margin-bottom: 16px;
}

.draft-title {
    width: 112px;
    font-weight: 400;
    font-size: 16px;
}

.draft-content > span {
    font-size: 14px;
}
.draft-date {
    /* margin-left: 0px; */
}
.draft-delete {
    color: #b5b5b5;
    margin-right: 10px;
}

@media (max-width: 640px) {
    .draft {
        left: 0;
        margin: 28px 16px 0;
        width: calc(100% - 32px);
        box-sizing: border-box;
    }
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin: 60px 0 200px;
}

.pagination > button {
    padding: 0px;
    width: 38px;
    height: 38px;
    background-color: transparent;
    /* border: 1px solid #a5a5a5; */
    color: #a5a5a5;
    font-size: 1.2rem;
    opacity: 0.7;
}
.pagination > button:hover {
    /* background-color: #a5a5a5; */
    /* color: #fff; */
}

.pagination > button.now {
    font-weight: 600;
    /* border: 2px solid #1f1f1f; */
    color: #1f1f1f;
    opacity: 1;
}
.pagination > button.now:hover {
    /* background-color: #1f1f1f; */
    /* color: #fff; */
}

@media (max-width: 640px) {
    .pagination {
        flex-wrap: wrap;
    }
}

.mypage-header {
  display: flex;
  justify-content: center;
}
.mypage-profile {
  display: flex;
  justify-content: space-between;
  width: 820px;
  height: 110px;
  border-radius: 20px;
  padding: 40px 28px;
}

.mypage-profile .img_profile {
  width: 52px;
}

.profile-left-top {
  display: flex;
  align-items: center;
}
.profile-left-top > div > span:first-of-type {
  margin-left: 14px;
  font-size: 36px;
  font-weight: 700;
}
.profile-left-top > div > span:last-of-type {
  margin-left: 10px;
  font-size: 20px;
}

.profile-left-bottom > span {
  font-size: 20px;
}

.profile-left-bottom {
  margin-left: 70px;
  margin-top: 22px;
}
.profile-left-bottom > span:nth-of-type(2n) {
  margin: 0px 16px;
  color: #dfdfdf;
}
.profile-left-bottom > button {
  margin-left: 26px;
  border-radius: 100px;
  padding: 6px 12px;
  font-size: 14px;
}

.profile-right {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #a5a5a5;
}
.profile-right-btn:not(:last-of-type) {
  margin-right: 30px;
}

.mypage-header ~ .forum-search {
  display: flex;
  align-items: center;
  margin-top: 100px;
}
.mypage-header ~ .forum-search > span {
  font-size: 24px;
  margin-right: 40px;
  cursor: pointer;
}

.mypage-header ~ .forum-search > a.checked {
  font-weight: 700;
  color: #f91137;
}

.mypage .forum-bottom,
.mypage .forum-bottom span {
  display: none;
  pointer-events: none;
}

.popup-container {
  width: 600px;
  /* height: 870px; */
  position: absolute;
  top: 100px;
  left: 500px;
  background-color: #fff;
  border: 0.5px solid;
  display: flex;
  flex-direction: column;
  z-index: 3;
}
.popup-img-container {
  text-align: center;
}
.popup-img {
  width: 600px;
  /* height: 770px; */
  object-fit: cover;
}
.popup-bottom {
  background-color: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup-bottom > span:hover {
  color: #f91137;
  cursor: pointer;
}
.popup-today {
  margin-left: 30px;
}

.popup-close {
  float: right;
  margin-right: 30px;
}

@media (max-width: 640px) {
  .popup-container {
    display: none;
  }
}

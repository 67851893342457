.header {
  /* 로고,사용자 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  height: 30px;
  margin-top: 50px;
  margin-bottom: 65px;
  bottom: 0px;
}

.header-logo {
  /* 로고div */
  width: 182px;
}

.header-logo > a {
  /* link를 a로 인식 */
  width: inherit;
}

.img_logo {
  /* 로고이미지 */
  width: inherit;
}

.header-wrap {
  /* 프로필,알람,달력 */
  line-height: 30px;
}
.header-wrap > a {
  cursor: pointer;
}
.header-wrap > span {
  margin: 0px 12px;
}

.header-wrap > div {
  cursor: pointer;
}

.header-wrap > div:not(:last-of-type) img {
  /* 우측상단 이미지 크기 */
  height: 20px;
}
.img_calendar {
  height: 20px;
  margin-left: 15px;
  padding-bottom: 2px;
}

.img_close {
  width: 34px;
  height: 34px !important;
  margin-left: 10px;
  /* margin-top: -7px; */
  cursor: pointer;
}

.header-profile {
  /* left해야 순서대로 */
  float: left;
  display: flex;
  flex-direction: column;
  width: 100px;
}

.header-profile-none {
  /* 이름 수직정렬 */
  font-size: 16px;
  display: flex;
  align-items: center;
  min-width: 100px;
}
.header-profile-none span {
  margin-left: 2px;
}

.img_profile {
  /* 프로필이미지 */
  margin-right: 5px;
}

.header-profile-name {
  /* 이름 */
  text-decoration: underline;
  text-underline-position: under;
}

.header-alarm {
  /* 알람 */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20px;
  height: 30px;
  float: left;
  margin-left: 10px;
  margin-right: 5px;
}
.menu-list {
  display: none;
}
.header-calendar {
  /* 캘린더 */
  display: flex;
  align-items: center;
  height: 30px;

  float: right;
  width: 34px;
}

/* 모바일 햄버거 메뉴 */
.header-wrap-mobile {
  cursor: pointer;
  display: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 3;
}
.header-wrap-mobile.active {
  background-color: var(--adpower);
}
.backdrop {
  display: none;
  z-index: 2;
}
.menu-icon,
.menu-icon-open {
  position: relative;
  display: block;
  width: 28px;
  height: 20px;
  z-index: 2;
}
.menu-icon-open {
  left: 50%;
  transform: translateX(-45%) scale(0.7);
}
.menu-icon i,
.menu-icon-open i {
  position: absolute;
  display: block;
  width: 28px;
  height: 3px;
  background-color: #000;
  transition: all 0.3s;
}
.menu-icon i:nth-child(1) {
  top: 0;
}
.menu-icon i:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.menu-icon i:nth-child(3) {
  bottom: 0;
}
.menu-icon-open i:nth-child(1) {
  top: 0;
  transform: rotate(45deg);
  transform-origin: left top;
  background-color: #fff;
}
.menu-icon-open i:nth-child(2) {
  opacity: 0;
}
.menu-icon-open i:nth-child(3) {
  bottom: 0;
  transform: translateY(2px) rotate(-45deg);
  transform-origin: left bottom;
  background-color: #fff;
}

@media (max-width: 640px) {
  .header {
    margin: 28px 0 32px;
  }
  .header-logo img {
    width: 80%;
  }
  .header-wrap {
    display: none;
  }
  .header-wrap-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu-list {
    position: fixed;
    display: block;
    padding: 0 20px 0 28px;
    top: 50%;
    right: 0;
    width: 77vw;
    height: calc(100% - 28px);
    background-color: #fff;
    border-radius: 20px 0 0 20px;
    transform: translateX(100%) translateY(-50%);
    transition: transform 0.3s, opacity 0.3s;
    box-sizing: border-box;
    overflow: auto;
    z-index: 2;
  }
  .menu-list hr {
    margin: 0;
  }
  .backdrop {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }
  .open {
    opacity: 1;
    box-shadow: 4px 6px 60px rgba(0, 0, 0, 0.25);
    transform: translateX(0%) translateY(-50%);
  }
  .user-info-not-logged {
    display: flex;
    padding: 6px 6px 12px;
    gap: 8px;
  }
  .header-profile-name {
    font-size: 1.3rem;
    font-weight: 600;
    text-decoration-line: none;
  }
  .header-profile-none span {
    margin-left: 4px;
  }
  .img_alarm,
  .img_calendar,
  .img_profile {
    margin: 0;
    width: 28px;
    height: auto;
    display: block;
  }
  .header-user-bottom {
    display: flex;
    margin: -2px 0 0;
    padding: 0 0 16px;
    align-items: flex-end;
    justify-content: space-between;
  }
  .header-logout {
    font-size: 0.9rem;
  }
  .img_alarm,
  .img_calendar {
    padding: 0 6px;
  }
  .header-icon {
    display: flex;
    align-items: center;
  }
  .header-user-detail {
    display: flex;
    gap: 4px;
    font-size: 0.95rem;
  }
  .header-user-detail-grade::before {
    content: "•";
    display: inline-block;
    margin-right: 4px;
  }
  .user-name-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

.user-info {
  position: sticky;
  top: 0;
  background-color: #fff;
  align-items: center;
  margin: 0 0 0 -6px;
  padding-top: 16px;
  gap: 8px;
  z-index: 1;
}
.header-menu-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}
.gnb-info {
  margin: 20px 0 0;
  padding: 0 0 32px;
}

.board {
  /* 게시판 상단부분  */
  margin-top: 68px;
  margin-bottom: 20px;
}
.board-top > span {
  font-size: 28px;
  font-weight: bold;
}

.board-flex {
  /* 양끝으로 하기위한 부모 */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.board-flex > .middle-margin {
  /* flex에서 양 끝으로 (오른쪽거에) */
  margin-left: auto;
}

.board-top {
  /* 글쓰기, 저장버튼들 */
}

.board-top-btn {
  /* 임시저장 취소 등록 */
}
.board-top-btn:not(:first-of-type) {
  margin-left: 8px;
}
.board-top-btn:not(:last-of-type) {
  margin-right: 8px;
}

.board-mid {
  /* 말머리 */
  display: flex;
  align-items: center;
}

.board-mid > .board-mid-category {
  /* 말머리 */
  width: 320px;
  float: left;
}
.board-mid > .board-title-input {
  width: 293px;
}
.board-mid > .board-title-input:last-of-type {
  margin-left: 18px;
}
.dropdown {
  position: relative;
  /* 드롭다운 */
  width: inherit;
  height: 38px;
  line-height: 38px;
  border: 1px #dfdfdf solid;
  border-radius: 8px;
  font-size: 16px;
  padding-left: 20px;
  box-sizing: border-box;
}

.dropdown-list {
  /* 드롭다운 리스트 */
  position: absolute;
  margin: 8px 0 0;
  width: 100%;
  left: 0;
  height: auto;
  border: 1px #dfdfdf solid;
  border-radius: 8px;
  background-color: #ffffff;
  z-index: 20; /*Quill보다 위로가게*/
}
.dropdown-list > ul {
  border: none;
  -webkit-padding-start: 0px;
  margin: -1px;
  max-height: 500px;
  overflow-y: auto;
}

.dropdown-list > ul > li {
  list-style: none;
  height: 36px;
  line-height: 36px;
  padding-left: 20px;
}
@media (hover: hover) {
  .dropdown-list > ul > li:hover {
    background-color: rgba(223, 223, 223, 0.4);
  }
  .dropdown-list > ul > li:first-of-type:hover {
    /* border-radius: 8px 0px; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .dropdown-list > ul > li:last-of-type:hover {
    /* border-radius: 8px 0px; */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.btn-thumbnail {
  /* width: 126px; */
  height: 38px;
  font-size: 16px;
  color: #1f1f1f;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin-left: 20px;
}

.thumbnail-preview {
  width: 30px;
  height: 30px;
  margin-left: 15px;
  margin-right: 10px;
}
.thumbnail-delete {
  background-color: inherit;
  color: black;
  padding: 6px 0px;
}

.board-bottom {
  /* 제목, 중요글등록 */
  display: block;
  margin-top: 18px;
  overflow: hidden;
}

.board-bottom > .board-title {
  /* 제목 칸 */
  width: 624px;
  float: left;
}
/* .board-bottom > .board-title >  */
.board-title-input {
  width: 100%;
  height: 36px;
  border: 1px #dfdfdf solid;
  border-radius: 8px;
  padding: 0px 0px 0px 18px;
  font-size: 16px;
}

.board-bottom > .board-important {
  display: flex;
  float: left;
  margin-left: 36px;
  line-height: 38px;
  align-items: center;
  gap: 8px;
}

input[type="checkbox"] {
  display: none;
}
.chk_imp + label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #a5a5a5;
}

#chk_imp + label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #a5a5a5;
}

.img_checkred {
  display: none;
  width: 12.5px;
  height: 8.5px;
  line-height: 20px;
}

#chk_imp:checked + label > .img_checkred {
  display: block;
  /* position: relative; */
}

.chk_imp:checked + label > .img_checkred {
  display: block;
}

.board-bottom > .board-important > span {
  margin-left: 15px;
}
.ql-formats {
  /* Quill이 위로 가는거 방지 */
  z-index: -999 !important;
}

.img_dropdown {
  width: 14px;
  height: auto;
  float: right;
  padding: 15px;
}

.img_check {
  display: none;
}

@media (hover: hover) {
  li:hover .img_check {
    display: block;
    width: 14px;
    height: auto;
    float: right;
    padding: 15px;
  }
}

.editor {
  margin-top: 17px;
  margin-bottom: 200px;
}

.quill {
  min-height: 600px !important;
  height: auto !important;
}
.editor .ql-container {
  min-height: 600px !important;
}

.quill-read {
  min-height: 100px !important;
  min-height: 600px !important;

  /* white-space: pre; */
}

/* 비디오 */
.ql-editing {
  left: 180px !important;
  top: 0px !important;
}

.ql-editor {
  min-height: 600px !important;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "비디오 삽입" !important;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "등록" !important;
}

.qna-recaptcha {
  position: absolute;
  display: flex;
  justify-content: center;
  margin-left: 835px;
  height: 0;
}

.invalid-input-title {
  position: absolute;
  margin-top: 10px;
  margin-left: 400px;
  color: #f91137;
}

/* .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;

  font-size: attr(data-value) !important;
} */

/* .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
  font-size: 12px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
  font-size: 20px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
  font-size: 24px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36px";
  font-size: 36px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: "48px";
  font-size: 48px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="60px"]::before {
  content: "60px";
  font-size: 60px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="72px"]::before {
  content: "72px";
  font-size: 72px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="84px"]::before {
  content: "84px";
  font-size: 84px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="96px"]::before {
  content: "96px";
  font-size: 96px !important;
} */

@media (max-width: 640px) {
  .board-flex {
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 8px;
  }
  .board-top > span {
    width: 100%;
  }
  .board-top-btn:not(:last-of-type),
  .board-top-btn:not(:first-of-type) {
    padding: 8px 12px;
    min-width: 76px;
    margin: 0;
  }
  .board-mid {
    gap: 12px;
    align-items: center;
  }
  .qna-editor .board-mid {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 8px;
    overflow: visible;
  }
  .board-mid > .board-title-input {
    width: 100%;
  }
  .board-mid > .board-title-input:last-of-type {
    margin: 0;
  }
  .board-mid > .board-mid-category {
    flex: 4;
    width: auto;
  }
  .btn-thumbnail {
    flex: 1.5;
    margin-left: 0;
    padding: 0 4px;
    box-sizing: border-box;
  }
  .dropdown {
    position: relative;
  }
  .dropdown .img_dropdown {
    padding: 15px 12px 15px 0;
  }
  .dropdown-list {
    margin: 42px 0 0;
    padding: 4px 0;
    width: 100%;
    left: 0;
    top: 0;
  }
  .board-bottom {
    display: flex;
    justify-content: space-between;
  }
  .board-bottom > .board-title {
    flex: 3;
  }

  .board-bottom > .board-important {
    justify-content: flex-end;
    flex: 1;
    margin: 0;
    gap: 8px;
  }
  .board-title-input {
    padding: 8px 12px;
    height: auto;
    box-sizing: border-box;
  }
  .board .qna-recaptcha {
    margin: 184px 0 0;
  }
  .qna-editor {
    margin: 120px 0;
  }
}

.info {
    /* display: flex;
  flex-direction: column;
  align-items: center; */
    margin-bottom: 200px;
}
.info-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.info > .dropdown {
    width: 320px;
    height: 56px;
    line-height: 56px;
}

.info .img_dropdown {
    padding: 24px;
    margin-bottom: 60px;
}

.info > .dropdown > .dropdown-list {
    margin-top: 17px;
}

.info > .dropdown > .dropdown-list > ul > li {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info > .dropdown > .dropdown-list > ul > li > img {
    margin-right: 10px;
}

.info-container > img {
    width: 100%;
}

.info-container > button {
    padding: 11.5px 24px;
    width: 300px;
    background-color: #f91137;
    margin-top: 60px;
}

@media (max-width: 640px) {
    .info > .dropdown > .dropdown-list {
        margin-top: 60px;
    }
}

#all {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    min-width: 1440px;
}

.container {
    max-width: 1140px;
    width: 100%;
    overflow: visible;
}
.mainpage {
    background-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 1) 30%
        ),
        linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.5)
        ),
        linear-gradient(
            261.73deg,
            rgba(142, 0, 0, 0.15) 22.59%,
            rgba(0, 0, 0, 0.105) 83.67%
        );

    /* z-index: -2; */
}

.mainpage .container {
    z-index: 1;
    /* margin-bottom: 200px; */
}

@media (max-width: 640px) {
    #all {
        min-width: auto;
        display: block;
        padding: 0 16px;
    }
    .container {
        max-width: 640px;
    }
}

/* --------------------------------------------------------------- */

/* --------------------------------------------------------------- */

p {
    margin: 0;
}

.link {
    text-decoration-line: none;
}

hr {
    background-color: #dfdfdf;
    height: 2px;
    border: none;
    border-radius: 100px;
    margin-top: 22px;
    margin-bottom: 18px;
}

.hidden {
    visibility: hidden;
}

.img_checkBox {
    width: 20px;
}

button {
    padding: 6px 24px;
    border: none;
    border-radius: 5px;
    background-color: #a5a5a5;
    font-size: 18px;
    text-decoration-line: none;
    color: #ffffff;
    cursor: pointer;
}

button.btn-write {
    background-color: #f91137;
}

input::placeholder {
    color: #a5a5a5;
}

.shadow {
    box-shadow: 4px 6px 60px rgba(0, 0, 0, 0.1);
}

span {
    text-decoration: none;
}
span:-webkit-any-link {
    color: #1f1f1f;
}

a {
    text-decoration: none;
}
a:-webkit-any-link {
    color: #1f1f1f;
}

.clamp1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}
.clamp2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: keep-all;
}
/* --------------------------------------------------------------- */

/* --------------------------------------------------------------- */

.not-found {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.not-found-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.not-found img {
    width: 300px;
}
.not-found p:first-of-type {
    color: #f91137;
    font-size: 32px;
    font-weight: 700;
    margin-top: 20px;
}

.not-found p:not(:first-of-type) {
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: -20px;
}

.not-found-contents > div:last-of-type {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

#all:has(.admin) {
    align-items: flex-start;
}
#all:has(.admin) > .container {
    display: none;
}

.container:has(.not-found) > .header,
.container:has(.not-found) > .gnb,
.container:has(.not-found) + .footer {
    display: none;
}

/* --------------------------------------------------------------- */

.null {
    /* height: 600px; */
    /* background-color: white; */
}

.loading-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #ffffffb7;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingText {
    text-align: center;
    font-size: 40px;
}

/* --------------------------------------------------------------- */

.pointer {
    cursor: pointer;
}

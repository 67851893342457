.gnb {
    display: flex;
    justify-content: center;
    margin-bottom: 110px;
    height: 50px;
}

.gnb-items {
    display: flex;
    font-size: 20pt;
    justify-content: center;
    text-align: center;
    /* padding-bottom: 100px; */
}

.gnb-items > span {
    font-size: 20px;
    font-weight: 600;
    width: 140px;
}

.gnb-item-cover {
    width: 1050px;
    height: 333px;
    margin-top: 40px;
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 2;
}

.gnb-item-cover > div {
    display: flex;
    width: 1050px;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 14px;
}

@media (hover: hover) {
    .gnb-item-cover a:not(:hover) {
        /* 기본 검정 */
        color: #1f1f1f;
    }

    .gnb-item-cover:hover a {
        color: gray;
    }
}

.gnb-item-list > ul {
    text-align: center;
    -webkit-padding-start: 0px;
    width: 140px;
    margin-top: 40px;
}
.gnb-item-list > ul > li {
    list-style: none;
    font-size: 16px;
    margin-top: 28px;
}

@media (hover: hover) {
    .gnb-item-list > ul > li:hover a {
        color: #1f1f1f;
        font-weight: 800;
    }
}

@media (max-width: 640px) {
    .gnb {
        display: none;
    }
}

.brand-space {
  width: 1200px;
  height: 375px;
  display: flex;
  align-items: flex-end;
}

.brands {
  display: flex;
  overflow-x: scroll;
  width: 1000px;
}

.brands::-webkit-scrollbar {
  display: none;
}

.brand {
  text-align: center;
  width: 180px;
}
.brand > .center-line {
  margin-left: 45px;
  margin-bottom: 35px;
}
.brand > .center-line > .circle {
  position: relative;
  top: 30px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #7b7b7b;
}
.brand:first-of-type > .center-line > .circle {
  background-color: #f91137;
}

.brand > .center-line > hr {
  width: 180px;
}
.brand:last-of-type > .center-line > hr {
  width: 0px;
}

.brand > p {
  font-weight: 400;
  color: #b3b3b3;
  width: 107px;
  font-size: 19.6px;
}
.brand:first-of-type > .brand-year {
  color: #f91137;
}
.brand:first-of-type > .brand-name {
  font-weight: 700;
  color: #f91137;
}

@media (max-width: 640px) {
  .brands {
    width: 100vw;
    transform: scale(0.9);
    padding: 0 0 0 24px;
  }
  .brand {
    width: 100px;
  }
  .brand > .center-line > hr {
    width: 100px;
  }
}

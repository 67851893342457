.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);

    font-size: 18px;
}

.modal.openModal {
    display: flex;
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal > section {
    /* 모달 창 스타일  */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 28px;
    width: 460px;
    height: 236px;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 14px;
    background-color: #ffffff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-show 0.3s;
    overflow: hidden;
}

.modal > section > main {
    /* 텍스트 부분 스타일 */
    min-width: 300px;
    font-size: 1.15rem;
    font-weight: 500;
    line-height: 1.8rem;
    text-align: center;
}

.modal > section > .modal-footer > button {
    width: 140px;
    height: 40px;
    font-weight: 600;
    border-radius: 8px;
    font-size: 1rem;
}
.modal > section > .modal-footer > button:hover {
    opacity: 0.9;
}

.modal > section > .modal-footer > button.no {
    background-color: #dfdfdf;
}
.modal > section > .modal-footer > button.yes {
    margin-left: 10px;
    background-color: #f91137;
}

.modal > section > .check-main {
    margin-top: 20px;
    text-align: center;
}
.modal-pw-input {
    margin-top: -5px;
    width: 70%;
    height: 30px;
    font-size: 20px;
    margin-bottom: 10px;
}

@media (max-width: 640px) {
    .modal.openModal,
    .openModal {
        padding: 0 20px;
    }
    .modal > section {
        height: auto;
        padding: 28px 24px;
        box-sizing: border-box;
    }
    .modal > section > .check-main {
        margin: 0;
    }
    .modal-pw-input {
        margin: 0;
        padding: 4px 12px;
        border: 1px solid #999;
        border-radius: 8px;
        font-size: 1rem;
    }
    .modal-footer {
        width: 100%;
        display: flex;
        gap: 12px;
        justify-content: center;
    }
    .modal > section > .modal-footer > button {
        padding: 14px 8px;
        flex: 1;
        max-width: 50%;
        width: auto;
        height: auto;
        word-break: keep-all;
    }
    .modal > section > .modal-footer > button.yes {
        margin: 0;
    }
}

.exhibition {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.exhibition-main {
    width: 800px;
    height: 1200px;
    margin-bottom: 100px;
    /* background-color: green; */
}
.exhibition-main > a > img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.exhibition-before-top {
    width: 1024px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
.exhibition-before-top > span {
    font-size: 28px;
    font-weight: 700;
}
.exhibition-before-top > div {
    font-size: 20px;
    font-weight: 600;
    color: #a5a5a5;
}
.exhibition-before-top > div > span:first-of-type {
    cursor: pointer;
    margin-right: 5px;
}

.exhibition-before-slider {
    /* min-width: 1200px;
  max-width: 1200px; */
    /* width: 1200px !important; */
}

.exhibition-before-slider .slick-arrow {
    width: 50px;
    height: 50px;
}
.exhibition-before-slider .slick-arrow::before {
    color: #1f1f1f;
    font-family: system-ui;
    font-size: 50px;
}
.exhibition-before-slider .slick-prev {
    margin-left: -40px;
}
.exhibition-before-slider .slick-prev::before {
    content: "＜";
}
.exhibition-before-slider .slick-next {
    margin-right: -40px;
}
.exhibition-before-slider .slick-next::before {
    content: "＞";
}

.exhibition-before-slider .slick-list {
    width: 1040px;
}

.exhibition-before-slider .slick-slide {
    /* display: flex; */
    /* justify-content: center; */
    /* width: 360px !important; */
    /* padding: 0px 10px; */
}

.exhibition-before-container {
    padding: 0 13px;
}
.exhibition-before-container img {
    border-radius: 30px;
    width: 320px;
    height: 480px;
    object-fit: cover;
}

.forum:not(:has(.pagination)) > .forum-bottom {
    margin-bottom: 280px;
}

.exhibition ~ .forum-bottom .btn-write {
    margin-right: 70px;
    margin-top: 20px;
    /* margin-bottom: 280px; */
}

.exhibition-grid {
    display: grid;
    grid-template-columns: 460px 460px;
}
.exhibition-grid > a {
    margin-bottom: 60px;
}
.exhibition-grid > a:nth-child(2n) {
    margin-left: 60px;
}
.exhibition-grid > a:nth-last-child(-n + 2) {
    margin-bottom: 0px;
}

.exhibition-grid > a > .exhibition-each > img {
    width: 448px;
    height: 672px;
    border-radius: 20px;
}

@media (max-width: 640px) {
    .exhibition-main {
        width: 80%;
        height: auto;
        margin: 0 0 56px;
    }
    .exhibition-before {
        width: 100%;
    }
    .exhibition-before-top {
        width: 100%;
    }
    .exhibition ~ .forum-bottom .btn-write {
        margin: 0;
    }
    .exhibition-before-slider .slick-list {
        padding: 0 100px;
        transform: translateX(-100px);
    }
    .exhibition-before-slider .slick-arrow {
        width: 70px;
        height: 70px;
        z-index: 1;
        padding: 20px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.1);
    }
    .exhibition-before-slider .slick-arrow::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .exhibition-before-slider {
        transform: scale(0.75);
    }
    .slick-disabled {
        display: none !important;
    }
    .exhibition-grid {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .exhibition-grid > a > .exhibition-each > img {
        width: 100%;
        height: auto;
    }
    .exhibition-grid > a:nth-child(2n) {
        margin: 0;
    }
    .exhibition-grid > a {
        margin: 0;
    }
}

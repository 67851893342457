.main1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -80px;
}

.main1-pi {
  align-items: center;
  display: flex;
  height: 400px;
}
.main1-post {
  width: 595px;
  margin-left: 40px;
  margin-right: 40px;
  z-index: 1;
}

.main1-not-login {
  text-align: right;
  margin-right: 20px;
  width: 500px;
}

.main1-post-week {
  font-size: 24px;
}
.main1-post-title {
  /* 메인 타이틀 */
  font-size: 48px;
  font-weight: 700;
  margin: 30px 0px;
  width: 556px;
}

.main1-not-login > .main1-post-title {
  margin-top: 18px;
  /* margin-right: 0px !important; */
  width: auto;
}
.main1-not-login > .main1-post-slogan {
  color: #f91137;
  font-size: 24px;
  font-weight: 700;
}
.main1-not-login > p {
  margin-top: 15px;
}
.main1-not-login > p:not(:first-of-type) {
  font-size: 52px;
}
.main1-not-login > .main1-post-title1 {
  font-weight: 400;
}
.main1-not-login > .main1-post-title2 {
  font-weight: 700;
}

.main1-post-go {
  /* 바로가기 버튼 */
  width: 120px;
  height: 40px;
  background-color: #f91137;
  border: none;
  border-radius: 5px;
  font-size: 14pt;
  color: #ffffff;
}

.main1-post-img {
  /* 메인 이미지 */
  width: 500px;
  z-index: -1;
}

.main1-post-img img {
  width: 726px;
  margin-bottom: -200px;
  margin-left: -40px;
}

.main1-departments {
  width: 100%;
  height: 220px;
  margin: 94px 0 120px;
  padding: 0 33px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  list-style: none;
  gap: 60px;
}
.main1-departments-dp {
  cursor: pointer;
  position: relative;
  flex: 1;
}
.main1-departments-dp::after {
  content: "";
  position: absolute;
  display: block;
  margin: 0 -28px;
  right: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #dfdfdf;
  border-radius: 100px;
}
.main1-departments-dp:last-child:after {
  display: none;
}

.main1-departments-dp > .dp-initial {
  font-size: 36px;
  font-weight: 900;
  margin: 0;
}

.main1-departments-dp > .dp-new {
  font-size: 12px;
  font-weight: 700;
  color: #f91137;
  margin-top: 20px;
}

.main1-departments-dp > div {
  display: flex;
  align-items: center;
  margin-top: 9px;
  margin-bottom: 18.5px;
  justify-content: space-between;
}
.main1-departments-dp .dp-name {
  font-size: 24px;
  font-weight: 700;
}
.main1-departments-dp .img_arright {
  width: 24px;
  height: 24px;
}
.main1-departments-dp > .dp-title {
  height: 29px;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box; /* 현재 브라우저에서 지원하는 flex 대신 사용 */
  -webkit-line-clamp: 2; /* 최대 2줄 까지 보여주도록 설정 */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.v-line {
  width: 2px;
  height: 177.85px;
  background-color: #dfdfdf;
  border-radius: 100px;
  margin: 0px 30px;
}

@media (max-width: 640px) {
  .main1 {
    position: relative;
    margin: 40px 0 0;
    width: 100%;
  }
  .main1-pi {
    position: relative;
    display: block;
    width: 100%;
    height: 230px;
  }
  .main1-post-img {
    position: relative;
    width: 100%;
    transform: translate(-12px, 84px);
    opacity: 0.7;
    z-index: 0;
  }
  .main1-post-img img {
    display: block;
    width: 100%;
  }
  .main1-post {
    position: absolute;
    margin: 0;
    padding: 0 8px;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: right;
    border-radius: 20px;
    box-sizing: border-box;
  }
  .main1-post-week {
    font-size: 1.1rem;
    opacity: 0.65;
  }
  .main1-post-title {
    width: 100%;
    font-size: 2.25rem;
    box-sizing: border-box;
  }
  .main1-post-slogan {
    letter-spacing: -0.3px;
  }
  .main1-not-login > p:not(:first-of-type) {
    font-size: 38px;
  }

  .main1-departments {
    width: 93%;
    height: auto;
    margin: 80px 0;
    padding: 24px 33px 32px;
    flex-direction: column;
    z-index: 1;
    gap: 40px;
  }
  .main1-departments-dp {
    cursor: pointer;
    position: relative;
    flex: 1;
    width: 100%;
  }
  .main1-departments-dp::after {
    content: "";
    position: absolute;
    display: block;
    margin: -16px 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: #dfdfdf;
    border-radius: 100px;
  }
  .main1-departments-dp:first-child:after {
    display: none;
  }
  .main1-departments-dp:last-child:after {
    display: block;
  }
  .main1-departments-dp > div {
    position: absolute;
    width: 100%;
    padding: 0 0 0 34px;
    top: 0;
    left: 0;
    box-sizing: border-box;
  }
  .main1-departments-dp > .dp-new {
    position: absolute;
    margin: 16px 28px 0 0;
    top: 0;
    right: 0;
  }
  .main1-departments-dp > .dp-title {
    margin: 12px 0 0;
    height: 1rem;
    font-size: 0.85rem;
    -webkit-line-clamp: 1; /* 최대 2줄 까지 보여주도록 설정 */
  }
}

/* -------------------------------------------------------------------- */

.main2 {
  overflow: visible;
  width: 1000%;
  background-color: #f91137;
  height: 661px;
  transform: translate(-45%); /*야매*/
}

.main-context {
  display: flex;
  justify-content: center;
}

.main2-mainpost {
  width: 680px;
  margin-top: 133px;
  margin-left: 65px;
}

.main2-mainpost > a {
  color: #ffffff;
  display: block;
}

.main2-mainpost > a > p {
  width: 472px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.main2-lnb {
  font-size: 22px;
  font-weight: 700;
}

.main2-title {
  font-size: 34px;
  font-weight: 700;
  margin-top: 20px !important;
}

.main2-img {
  margin-left: -220px;
  background-color: #1f1f1f;
  clip-path: path(
    "M516.719 0H195.532C187.916 0 180.962 4.32504 177.594 11.1555L2.22202 366.837C-4.33132 380.129 5.34108 395.682 20.1601 395.682H417.389C427.093 395.682 435.397 388.716 437.084 379.159L455.665 273.924C457.352 264.368 465.656 257.402 475.36 257.402H503.23C582.23 257.402 649 207.525 649 108.917C649 14.4735 584.478 1.09333 516.719 0Z"
  );
}
.img_aa {
  opacity: 0.7;
  width: 660px;
  height: 400px;
  clip-path: path(
    "M516.719 0H195.532C187.916 0 180.962 4.32504 177.594 11.1555L2.22202 366.837C-4.33132 380.129 5.34108 395.682 20.1601 395.682H417.389C427.093 395.682 435.397 388.716 437.084 379.159L455.665 273.924C457.352 264.368 465.656 257.402 475.36 257.402H503.23C582.23 257.402 649 207.525 649 108.917C649 14.4735 584.478 1.09333 516.719 0Z"
  );
}
.main2-sublist {
  margin-top: 133px;
  width: 500px;
}

.main2-mainpost > a:not(:last-of-type):not(:first-of-type) {
  margin-top: 122px;
}

.main2-subpost:first-of-type {
}
.main2-subpost {
  margin-top: 25px;
  width: 374px;
}
/* .main2-subpost p:first-of-type {
  display: inline;
} */

.main2-subpost > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.main2-subpost span {
  margin-left: -150px;
  font-weight: 300;
}
.main2-subpost img {
  width: 16px;
}

.main2-sub-lnb {
  font-size: 18px;
  font-weight: 600;
}

.main2-sub-title {
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 640px) {
  .main2 {
    width: calc(100% + 32px);
    height: auto;
    margin: 0 -16px;
    transform: translate(0);
  }
  .main2 .main-context {
    position: relative;
    display: block;
    padding: 48px 24px 60px;
  }
  .main2-mainpost {
    margin: 0;
    width: 100%;
  }
  .main2-subpost {
    width: 100%;
  }
  .main2-subpost span p {
    width: 100%;
  }
  .main2-mainpost a:first-child .main2-title {
    width: calc(100% - 120px);
    font-size: 1.55rem;
    -webkit-line-clamp: 2; /* 최대 2줄 까지 보여주도록 설정 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: keep-all;
  }
  .main2-sublist {
    position: absolute;
    top: 0;
    right: 0;
    margin: 44px 15px 0;
    width: 300px;
    transform: scale(0.9);
    transform-origin: right top;
    z-index: -1;
  }
  .main2-img {
    width: 100%;
    margin: 0;
    clip-path: path(
      "M235.162 0H91.64C84.0239 0 77.0689 4.32555 73.7013 11.1566L2.21922 156.157C-4.33306 169.448 5.33939 185 20.1579 185H179.793C189.497 185 197.801 178.034 199.488 168.477L205.068 136.87C206.755 127.314 215.059 120.347 224.763 120.347H228.856C265.787 120.347 297 97.0276 297 50.9236C297 6.76706 266.838 0.511184 235.162 0Z"
    );
  }
  .img_aa {
    width: 100%;
    height: 100%;
    clip-path: path(
      "M235.162 0H91.64C84.0239 0 77.0689 4.32555 73.7013 11.1566L2.21922 156.157C-4.33306 169.448 5.33939 185 20.1579 185H179.793C189.497 185 197.801 178.034 199.488 168.477L205.068 136.87C206.755 127.314 215.059 120.347 224.763 120.347H228.856C265.787 120.347 297 97.0276 297 50.9236C297 6.76706 266.838 0.511184 235.162 0Z"
    );
  }
}

/* -------------------------------------------------------------------- */

.main3 {
  height: auto;
  width: 1000%;
  /* height: 100vh; */
  transform: translate(-45%); /*야매*/
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    linear-gradient(
      353.65deg,
      rgba(142, 0, 0, 0.2) 17.43%,
      rgba(204, 166, 69, 0.14) 94.98%
    );
}

.main3-distance {
  margin-top: 140px;
}
.main3-best3 {
  display: none;
}

.main3-info {
  display: flex;
}
.main3-info > img {
  cursor: pointer;
  height: 620px;
}

@media (max-width: 640px) {
  .main3 {
    width: calc(100% + 32px);
    margin: 0 -16px;
    transform: translateX(0);
  }
  .main3-info {
    display: none;
  }
  .main3-best3 {
    display: block;
    cursor: pointer;
    padding: 68px 0;
    width: 100%;
    background-color: #f7f1ea;
  }
  .best3-images {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .best3-image {
    overflow: hidden;
  }
  .best3-image img {
    width: 252px;
    height: 384px;
    object-fit: cover;
  }
  .best3-image:nth-child(1) {
    width: 232px;
    height: 200px;
    transform: translateX(-48px);
  }
  .best3-image:nth-child(2) {
    margin: -100px 0 0;
    width: 220px;
    height: 300px;
    transform: translateX(48px);
  }
  .best3-image:nth-child(3) {
    margin: 20px 0 0;
    width: 228px;
    height: 180px;
    transform: translateX(-48px);
  }
  .best3-image:nth-child(1) img {
    object-position: left;
  }
  .best3-image:nth-child(2) img {
    object-position: center;
  }
  .best3-image:nth-child(3) img {
    object-position: right;
  }
}

.main3-text {
  width: 238px;
}
.main3-text > p {
  font-size: 42px;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 0px;
}

.main3-text > p:first-of-type {
  color: #f91137;
  font-size: 22px;
  font-weight: 700;
  margin-top: 0px;
}
.main-brand {
  margin-top: 78px;
}
.main-brand > .main3-text {
  /* margin-right: -300px; */
  margin-left: 300px;
}

.project-img {
  display: flex;
  flex-direction: column;
}

.project-img:not(:last-of-type) {
  margin-left: 233px;
  margin-right: 60px;
  margin-top: 143px;
}

.project-img img {
  width: 304px;
  margin-bottom: 60px;
}

/* 애드파워는 지금 */
.main-now {
  margin-top: 183px;
  flex-direction: column;
  /* text-align: center; */
  align-items: center;
}

.main-now-text {
  width: auto;
  text-align: center;
}

/* SNS */
.sns-background {
  background-color: #ffffff;
  height: 140px;
  /* margin-top: 200px; */
}

.sns {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sns:not(:first-of-type) {
  margin-left: 40px;
}

.sns-logo {
  /* width: 76px; */
  height: 40px;
  margin-right: 30px;
}

.sns-name {
  font-size: 18px;
}
.sns-exp {
  font-size: 14px;
  color: #b3b3b3;
}

@media (max-width: 640px) {
  .main3 .main-context {
    display: block;
    position: relative;
  }
  .main3-text > p {
    margin-top: 0;
    font-size: 32px;
  }
  .main3-text > p:nth-child(2) {
    margin-top: 12px;
  }
  .main-brand > .main3-text {
    margin: 0;
  }
  .main-projects {
    display: flex !important;
    gap: 12px;
    margin: 0;
    padding: 268px 24px 0;
  }
  .main-projects > .main3-text {
    margin: 68px 0 0;
  }
  .project-img:not(:last-of-type) {
    margin: 0;
  }
  .project-img {
    gap: 12px;
  }
  .project-img img {
    margin: 0;
    width: 100%;
  }
  .main3-text {
    position: absolute;
    top: 0;
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }

  /* SNS */
  .sns-background {
    display: flex !important;
    padding: 0 48px;
    justify-content: space-between;
    gap: 16px;
  }
  .sns > div {
    display: none;
  }
  .sns > .sns-logo {
    margin: 0;
    width: 32px;
    height: auto;
  }
}

.admin {
  min-height: 150vh;
  display: flex;
  flex-direction: row;
}

.admin ~ .footer {
  display: none;
}

/*admin 좌측 메뉴*/
.admin-menu {
  width: 200px;
  background-color: #222222;
  color: #fff;
  text-align: center;
  margin-right: 34px;
}

#admin-menu-contents {
  position: absolute;
  top: 20px;
  left: 20px;
}

.admin-menu > div > img {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 150px;
  cursor: pointer;
}

.admin-menu-item {
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 18px;
}

/************************
************************
************************
************************
************************
***********admin user*/
.admin-contents {
  margin-bottom: 200px;
}

.admin-contents-title {
  font-size: 30px;
  font-weight: 600;
  margin-top: 54px;
}

.admin-user-header {
  width: 1240px;
  height: 140px;
  border-radius: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
}
.admin-user-header-left {
  margin-left: 100px;
}
.admin-user-header-radio {
  font-size: 18px;
}
.admin-user-header-radio > input {
  margin-left: 10px;
}
.admin-user-header-input > input {
  margin-top: 5px;
  margin-left: 10px;
  width: 400px;
  height: 38px;
  font-size: 18px;
}
.admin-user-header-right {
  margin-left: 160px;
}
.admin-user-header button {
  margin-left: 20px;
  width: 100px;
  height: 60px;
  background-color: #2f2f2f;
}

.admin-user-table {
  width: 1240px;
  border-radius: 20px;
  margin-top: 20px;
}

.admin-user-table--header {
  display: flex;
  align-items: center;
  font-size: 18px;
  height: 50px;
}
.admin-user-table--header > input + label {
  width: 25px;
  height: 25px;
  margin: 11px 3px;
}

.admin-user-table--header + hr {
  margin: 0px;
}

.admin-user-table-inner {
  padding: 20px 30px;
}
.admin-user {
  display: flex;
}

.admin-user + hr {
  margin: 0px;
}

.admin-user input {
  width: 95%;
  height: 25px;
  margin: 10px 3px;
  border: none;
  font-size: 16px;
}

.admin-user label {
  width: 25px;
  height: 25px;
  margin: 11px 3px;
}

.admin-user-nick-container {
  width: 215px;
  margin-left: 50px;
}
.admin-user-name-container {
  width: 155px;
}
.admin-user-department-container {
  width: 155px;
}
.admin-user-department-number-container {
  width: 155px;
}
.admin-user-class-container {
  width: 125px;
}
.admin-user-email-container {
  width: 250px;
}

.admin-user:has(.chk_imp:not(:checked)) > div > .admin-user-input {
  pointer-events: none;
}

/************************
************************
************************
************************
************************
***admin calendar*/

.admin-hearder--2 {
  width: 1240px;
  border-radius: 20px;
  padding: 40px;
  margin-top: 20px;
  display: flex;
}

.admin-calendar-container {
  width: 460px;
  /* padding: 20px 100px; */
  margin-top: 20px;
  margin-left: 160px;
}
.admin-calendar-container > div {
  margin-bottom: 27px;
}
.admin-calendar-container > button {
  float: right;
  width: 110px;
  height: 35px;
}
.admin-calendar {
}

.admin-calendar-title {
  font-size: 20px;
  font-weight: 600;
}

.admin-calendar-label {
  display: inline-block;
  width: 80px;
  font-size: 18px;
}
.admin-calendar-input {
  width: 342px;
  height: 30px;
  margin-left: 20px;
  background-color: #f2f2f2;
  border: #a5a5a5;
  font-size: 18px;
  padding-left: 10px;
}

/************************
************************
************************
************************
************************
***admin brand*/

.admin-brand-list {
  display: grid;
  grid-template-columns: repeat(3, 320px);
  margin-top: 30px;
}

.admin-brand-insert span {
  margin-right: 20px;
}

.admin-brand-insert input {
  margin-right: 20px;
}

/************************
************************
************************
************************
************************
***admin header*/

.gnb-relative {
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  margin-bottom: 20px;
}

.gnb-relative > .gnb-item-cover {
  position: relative;
  margin-top: 20px;
}

.gnb-relative li {
  cursor: pointer;
}

/************************
************************
************************
************************
************************
***admin popup*/
.admin-popup-container > * {
  margin: 20px;
}
.admin-popup-container > input[type="radio"] {
  transform: scale(1.5);
  margin: 0px;
}
.admin-popup-container > label {
  margin: 5px;
}
.admin-popup-container > .admin-brand-input {
  padding: 15px;
}

.admin-popup-insert {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}
.admin-popup-insert > div {
  margin-right: 20px;
}

.admin-popup-insert > div > div {
  font-size: 20px;
  margin: 0 0 10px 5px;
}

.admin-popup-insert > div > div > span {
  font-size: 20px;
  font-weight: 700;
  color: var(--adpower);
}

.admin-popup-insert .popup-img {
  width: 400px;
}

.admin-popup-mid {
  margin-top: 30px;
  width: 820px;
  display: flex;
  justify-content: center;
}
.admin-popup-mid button {
  height: 50px;
}
.admin-popup-mid button:hover {
  background-color: var(--adpower);
  color: #fff;
}
.admin-popup-bottom {
  display: flex;
  align-items: center;
}
.admin-popup-bottom button {
  margin-left: 20px;
}
.admin-popup-bottom span {
  margin-left: 20px;
}

.youtube-list {
  display: flex;
}

.youtube-list img {
  width: 200px;
}

/************************
************************
************************
************************
************************
***admin server데이터*/
.admin-server-data {
  display: flex;
}
.admin-server-data > * {
  display: inline;
  padding: 15px;
  border: 1px solid black;
}

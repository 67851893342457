.pwCheck {
  justify-content: center;
  margin-bottom: 200px;
}
.pwCheck h1 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 44px;
}

.userinfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userinfo > p {
  font-size: 32px;
  font-weight: 700;
}

.userinfo-container {
  width: 650px;
  margin-top: 77px;
}

.userinfo-container .readonly {
  pointer-events: none;
  color: #a5a5a5;
}

.userinfo .signup-input,
.userInfo-pw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userinfo .signup-container {
  position: relative;
}
.userinfo .signup-input + button {
  position: absolute;
  top: 50px;
  right: -10px;
  padding: 6px 15px;
  /* margin-right: 11px; */
  background-color: #b5b5b5;
  border-radius: 100px;
  font-size: 14px;
}

.signout-center {
  width: 650px;
  text-align: center;
}
.signout-center > p {
  font-size: 20px;
  margin: 5px;
}

.signout-center > button {
  width: 150px;
  height: 40px;
  margin: 20px 10px 0px 10px;
}

.lnb {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.gnb-item {
    /* 대분류 */
    font-size: 32px;
    font-weight: 700;
}

.lnb-items {
    margin-top: 30px;
    display: flex;
    /* justify-content: space-evenly; */
    justify-content: center;
    width: 80%;
}

.lnb-items > .item-checked > a {
    color: #f91137;
    font-weight: 700;
}
.lnb-items > .item-checked > .underline {
    height: 2px;
    background-color: #f91137;
    border-radius: 100px;
    border: none;
    margin-top: 10px;
}
.lnb-items > li {
    list-style: none;
}
.lnb-items > li:not(:first-of-type) {
    margin-left: 36px;
}

.lnb-items > li > a {
    text-decoration-line: none;
    font-size: 20px;
    font-weight: 500;
    color: #a5a5a5;
}
@media (max-width: 640px) {
    .lnb-items > li:not(:first-of-type) {
        margin: 0;
    }
    .lnb-items {
        width: 90%;
        flex-wrap: wrap;
        gap: 16px 24px;
    }
    .underline {
        display: none;
    }
    .lnb-items > li > a {
        padding: 2px 6px;
        font-size: 16px;
    }
}

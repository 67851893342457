.calendar {
  width: 486px;
  position: absolute;
  top: 100px;
  margin-left: -450px;
  border-radius: 20px;
  cursor: auto;
  z-index: 2;
}

.admin-calendar {
  width: 486px;
  /* border: 2px solid #1f1f1f; */
  border-radius: 20px;
}

.react-calendar {
  width: 100%;
  border: none;
  border-radius: 20px 20px 0px 0px;
  height: 540px;
}

.react-calendar__navigation {
  height: 69px;
}

.react-calendar__navigation__label {
  pointer-events: none;
}

/*  < 월 >  */
.react-calendar__navigation button {
  font-size: 24px;
  font-weight: 700;
  color: #1f1f1f !important;
  min-width: 80px;
  margin-top: 28px;
}

/* 일월화수목금토 */
.react-calendar__month-view__weekdays {
  margin-top: 19px;
  font-size: 15px;
  font-weight: 400;
  color: #a5a5a5;
  padding: 0 10px;
}
abbr[title] {
  text-decoration: none;
}

/* 달력 날짜부분 */
.react-calendar__month-view__days {
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

/* 클릭안된 기본 날짜들 */
.react-calendar__month-view__days > button > abbr {
  font-size: 20px;
  color: #a5a5a5;
}

/* 날짜 하나하나 */
.react-calendar__tile {
  padding: 0px;
  width: 63.5px;
  height: 64px;
}
.admin-calendar .react-calendar__tile:not(:has(.dot)) {
  pointer-events: all;
}
.react-calendar__tile:not(:has(.dot)) {
  pointer-events: none;
}

/* 오늘날짜 */
.react-calendar__tile--now {
  background-color: transparent;
}
/* 선택날짜 */
.react-calendar__tile--active {
  background-color: transparent;
}
@media (hover: hover) {
  /* 클릭시 */
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: transparent;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus > abbr {
    color: #1f1f1f;
    font-weight: 700;
  }
  /* hover시 배경색없애기 */
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #ffffff;
  }
}
/* 캘린더 안에 있는 점*/
.absolute {
  position: absolute;
  width: inherit;
  /* left: 50%; */
  /* transform: translate(100%, 0%); */
}
.dot-list {
  display: flex;
  margin-top: 14px;
  justify-content: center;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #f91137;
  border-radius: 50%;
  margin-left: 1px;
}

/* ------아래쪽------*/
.calendar-events-list {
  background-color: #ffffff;
  padding: 5px 20px 20px 20px;
  border-radius: 0px 0px 20px 20px;
}
.calendar-events-list > hr {
  margin: 20px 0px;
  height: 1.5px;
  background-color: #1f1f1f;
  width: 430px;
}

/* 선택 날짜 */
.checkDay {
  color: #f91137;
  font-size: 18px;
  font-weight: 700;
  margin-top: 0px;
  margin-left: 8px;
  margin-bottom: 17px;
}

/* 이벤트 하나하나 */
.events-list {
  display: flex;
  align-items: center;
  background-color: #dfdfdf;
  border-radius: 8px;
  height: 36px;
  padding: 4px 10px;
  margin-bottom: 10px;
}

.events-list > span {
  margin-left: 10px;
  font-size: 14px;
}
